import React from 'react';
import styled from 'styled-components';

import { Prev, Next } from './PrevNext';
import All from './All';

const SinglePagination = ({ next, previous, category }) => {
  console.log(next, previous);
  return (
    <Pagination className="single-pagination">
      {previous && <Prev link={`/painting/${previous}`} />}
      <All
        link={`/category/${category}`}
        style={{
          position: 'relative',
          display: category ? 'flex' : 'none',
          transition: 'none',
        }}
      />
      {next && <Next link={`/painting/${next}`} />}
    </Pagination>
  );
};

const Pagination = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 2rem 1rem;
  z-index: 0;
  .next {
    margin-left: 1rem;
  }
  .previous {
    /* margin-right: 1rem; */
  }
  .all-button {
    margin: 0;
    margin-left: 1rem;
    position: relative;
    left: initial;
    transform: none;
    bottom: initial;
  }
`;

export default SinglePagination;
