import React from 'react';
import { graphql } from 'gatsby';

import Wrapper from '../components/Wrapper';
import SinglePainting from '../components/SinglePainting';

const singlePainting = ({ data, pageContext }) => {
  const {
    sanityPainting: { seoSettings, title },
  } = data;
  return (
    <Wrapper className="single-painting" seo={seoSettings} title={title}>
      <SinglePainting {...data.sanityPainting} {...pageContext} />
    </Wrapper>
  );
};

export const SinglePaintingQuery = graphql`
  query SinglePaintingQuery($id: String!) {
    sanityPainting(id: { eq: $id }) {
      id
      images {
        _type
        _key
        alt
        asset {
          fluid(maxWidth: 800) {
            ...GatsbySanityImageFluid
          }
        }
      }
      info
      title
      _rawDescription
      categories {
        slug {
          current
        }
      }
      seoSettings {
        metaDescription
        title
      }
    }
  }
`;

export default singlePainting;
