import React, { useState, useEffect } from 'react';
import Img from 'gatsby-image/withIEPolyfill';
import styled from 'styled-components';
import BlockContent from '@sanity/block-content-to-react';
// import classNames from 'classnames';
import Flickity from 'react-flickity-component';
import 'flickity/css/flickity.css';
import { BsArrowUp } from '@react-icons/all-files/bs/BsArrowUp';
import { BsArrowDown } from '@react-icons/all-files/bs/BsArrowDown';
import { rgba } from 'polished';

import Main from './Main';
import SinglePagination from './SinglePagination';
import { SliderPrev, SliderNext } from './PrevNext';
import { useElementSize } from './hooks';
import { media } from './theme';

const SinglePainting = ({
  images,
  info,
  title,
  _rawDescription,
  categories,
  next,
  previous,
}) => {
  const [loading, setLoading] = useState(true);
  const [sliderRef, sliderWindow] = useElementSize();
  const [flickityRef, setFlickityRef] = useState();
  const [index, setIndex] = useState(0);
  const [scrollUp, setScrollUp] = useState(false);
  const [scrollDown, setScrollDown] = useState(false);

  useEffect(() => {
    if (
      _rawDescription &&
      !images.find(image => image._type === 'description')
    ) {
      images.splice(1, 0, {
        _type: 'description',
        _rawDescription,
      });
    }
  }, []);
  const len = images.length;
  const prevSlide = () => {
    setIndex((index + len - 1) % len);
    flickityRef.previous();
  };
  const nextSlide = () => {
    setIndex((index + 1) % len);
    flickityRef.next();
  };

  return (
    <Main single style={{ opacity: loading ? 0 : 1 }}>
      <h1 className="painting-title">{title}</h1>
      <Slider className="image-slider" ref={sliderRef} size={sliderWindow}>
        <Flickity
          options={{
            prevNextButtons: false,
            pageDots: false,
            on: {
              change(index) {
                setIndex(index);
              },
              ready() {
                setLoading(false);
              },
            },
          }}
          flickityRef={c => setFlickityRef(c)}
        >
          {images.map(item => {
            return item._type === 'description' ? (
              <Description
                className="description-wrapper"
                size={sliderWindow}
                key="description"
                scrollUp={scrollUp}
                scrollDown={scrollDown}
              >
                <div
                  className="description-scroller"
                  onWheel={event => {
                    if (event.nativeEvent.wheelDelta > 0) {
                      setScrollUp(true);
                      setTimeout(() => {
                        setScrollUp(false);
                      }, 500);
                    } else {
                      setScrollDown(true);
                      setTimeout(() => {
                        setScrollDown(false);
                      }, 500);
                    }
                  }}
                >
                  <BlockContent
                    className="description"
                    blocks={item._rawDescription}
                  />
                </div>
                <BsArrowUp
                  className="description-arrow description-up"
                  size="4rem"
                />
                <BsArrowDown
                  className="description-arrow description-down"
                  size="4rem"
                />
              </Description>
            ) : (
              <div className="image-wrapper" key={item._key}>
                <Img
                  fluid={item.asset.fluid}
                  alt={item.alt}
                  objectFit="contain"
                />
              </div>
            );
          })}
        </Flickity>
        <SliderPrev onClick={prevSlide} show={index !== 0} />
        <SliderNext onClick={nextSlide} show={index < len - 1} />
      </Slider>
      <p className="painting-info">{info}</p>
      <SinglePagination
        next={next}
        previous={previous}
        category={categories.length ? categories[0].slug.current : false}
      />
    </Main>
  );
};

const Slider = styled.div`
  position: relative;
  flex-grow: 1;

  .slider-button {
    top: 50%;
    transform: translateY(-50%);
    display: none;
    ${media.break`
      display: initial;
    `}
  }
  .slider-prev {
    left: 10px;
  }
  .slider-next {
    right: 10px;
  }
  .image-wrapper {
    width: ${({ size }) => size.width - 10 - 10 - 42}px;
    ${media.break`
    width: ${({ size }) => size.width - 70 - 70 - 10 - 10 - 42}px;
    `}
    height: ${({ size }) => size.height}px;
    margin: 0 10px;
    /* flex: 0 0 ${({ size }) => size.width - 20}px; */
    opacity: 0.5;
    transition: 0.25s;
    &.is-selected {
      opacity: 1;
    }
    .gatsby-image-wrapper {
      top: 50%;
      transform: translateY(-50%);
    }
  }
`;

const Description = styled.div`
  opacity: 0.5;
  transition: 0.25s;
  padding-right: 7rem;
  &.is-selected {
    opacity: 1;
  }
  width: ${({ size }) => size.width - 10 - 10 - 42}px;
  ${media.break`
  width: ${({ size }) => size.width - 70 - 70 - 10 - 10 - 42}px;
  `}
  height: ${({ size }) => size.height}px;
  margin: 0 10px;
  flex: 0 0 ${({ size }) => size.width - 20}px;
  background: ${({ theme }) => theme.black};
  color: white;
  font-family: ${({ theme }) => theme.font.mono};
  line-height: 1.3;
  padding: 1rem;
  position: relative;
  transform: translateX(0);
  .description-scroller {
    overflow: scroll;
    width: 100%;
    height: 100%;
  }
  .description-arrow {
    position: fixed;
    /* left: ${({ size }) => size.width * 2 - 70}px; */
    right: 2rem;
    top: 50%;
    color: ${rgba('white', 0.8)};
    transition: 0.15s;
    display: none;
    ${media.break`
      display: initial;
    `}
  }
  .description-up {
    transform: scaleY(1.5) translateY(-120%);
    color: ${({ scrollUp }) => rgba('white', scrollUp ? 1 : 0.8)};
  }
  .description-down {
    transform: scaleY(1.5) translateY(20%);
    color: ${({ scrollDown }) => rgba('white', scrollDown ? 1 : 0.8)};
  }
  ${media.break`
    padding: 3rem 3rem;
    padding-right: 7rem;
  `}
`;

export default SinglePainting;
